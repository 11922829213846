<template>
    <div class="page-title-section" style="background-image: url(img/heading.jpg);">
        <div class="container">
            <h1>{{ title }}</h1>
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><a>{{ title }}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Page Title',
        },
    },
}
</script>